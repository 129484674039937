.default {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  background-color: unset;
  position: relative;
  white-space: nowrap;
  &.loading {
    cursor: not-allowed;
    // background-color: #616161;
  }
  &.rounded {
    border-radius: 9999px;
  }
  &.lg {
    height: 53px;
    // min-width: 144px;
  }
  &.sm {
    height: 38px;
    // min-width: 128px;
  }
}

.main {
  position: relative;
  background: #ffffff;
  color: black;
  padding: 8px, 12px;
  font-size: 16px;
  &:not(:disabled):hover {
    background: #ffffffcc;
    color: black;
  }
  &:not(:disabled):active {
    background: #ffffff;
    color: black;
  }
  &:disabled {
    background: #ffffff66;
    color: white;
  }
}

.secondary {
  background: #ffffff1a;
  color: white;
  border: 1px solid #ffffff0f;
  box-shadow: -1px 1px 2px 0px #0000000f;
  font-size: 16px;
  &:not(:disabled):hover {
    background: #ffffff33;
    color: white;
  }
  &:not(:disabled):active {
    background: #ffffff33;
  }
  &:disabled {
    background: #ffffff33;
    color: #ffffff80;
  }
}

.gradient {
  background: linear-gradient(90deg, #a53bd0 0%, #2c3af5 105.26%);
  color: white;
  &:not(:disabled):hover {
    box-shadow: 0px 2px 6px 0px #4e1e63;
  }
  &:not(:disabled):active {
    background: linear-gradient(90deg, #ae4ed4 0%, #404cf8 105.26%);
  }
  &:disabled {
    @extend .disabled;
  }
}

.danger {
  background: rgb(250 225 225);
  color: rgb(252, 87, 87);
  // border: 2px solid #e2e2e2;
  &:not(:disabled):hover {
    background: rgb(250 225 225 / 50%);
  }
  &:not(:disabled):active {
    background: rgb(250 225 225 / 90%);
  }
  &:disabled {
    @extend .disabled;
  }
}

.link {
  color: #616161 !important;
  height: auto;
  padding: 0 0;
  width: fit-content;
  &:not(:disabled):hover {
    text-decoration: none;
    text-decoration-color: #616161;
  }
  &.disabled {
    color: #b3b3b3;
    cursor: not-allowed;
  }
}
