@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  min-height: 100vh;
  /* background-image: url("./assets/images/main-bg.png"); */
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-color: #121212;
  color: white;
}

@layer base {
  html {
    font-family: "archivo", system-ui, sans-serif;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff99;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid white;
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  /* background-color: #2196f3; */
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 3px;
  top: -1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The entire scrollbar */
::-webkit-scrollbar {
  width: 8px; /* width of the vertical scrollbar */
  height: 8px; /* height of the horizontal scrollbar */
}

/* The draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #ffffff1a;
  border-radius: 10px; /* rounded corners on the scrollbar thumb */
  border: 3px solid #ffffff1a; /* creates a border around the thumb */
}

/* The part of the scrollbar track where the thumb isn't */
::-webkit-scrollbar-track {
  background: #ffffff0a;
  border-radius: 10px; /* rounded corners on the track */
}

/* The buttons on the scrollbar (arrows pointing upwards and downwards) */
::-webkit-scrollbar-button {
  display: none; /* often, it's better to hide these for a cleaner look */
}

span[aria-live="polite"] {
  /* CSS properties here */
  line-height: 100%;
}

.drop-shadow-x {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-telegram,
.drop-shadow-whatsapp {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-reddit,
.drop-shadow-facebook {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.social-button-group a:hover {
  filter: drop-shadow(0 4px 20px #ffffff67);
}

.drop-shadow-x {
  --tw-drop-shadow: drop-shadow(0 0 15px #ffffff80);
}

.drop-shadow-telegram {
  --tw-drop-shadow: drop-shadow(0 0 15px #0088cc80);
}

.drop-shadow-facebook {
  --tw-drop-shadow: drop-shadow(0 0 15px #3b599880);
}

.drop-shadow-whatsapp {
  --tw-drop-shadow: drop-shadow(0 0 15px #25d36680);
}

.drop-shadow-reddit {
  --tw-drop-shadow: drop-shadow(0 0 15px #d3632e80);
}

.ant-slider {
  width: 100%;
  margin: 0 !important;
}

.ant-slider-rail {
  height: 36px !important;
  padding: 4px;
  border-radius: 56px !important;
  background: #0000008c !important;
  border: 1px solid #ffffff0f;
}

.ant-slider-track {
  height: 28px !important;
  border: 1px solid #ffffff0f !important;
  /* border-radius: 56px !important; */
  border-top-left-radius: 56px !important;
  border-bottom-left-radius: 56px !important;
  box-shadow: 0px 4px 16px 0px #3fcf8a4a;
  background: linear-gradient(90deg, #73ffab 0%, #459967 100%);
  top: 7px !important;
}

.ant-slider-handle {
  border: 1px solid #ffffff0f !important;
  border: 4px solid #7ad69f !important;
  box-shadow: 0px 4px 16px 0px #3fcf8a4a !important;
  background: #ffffff !important;
  width: 28px !important;
  height: 28px !important;
  border-radius: 36px !important;
  top: 7px !important;
}

.ant-slider-handle::before {
  display: none !important;
}

.ant-slider-handle::after {
  display: none !important;
}

.slider-tooltip {
  position: relative;
}

.ant-slider-tooltip {
  cursor: pointer;
  z-index: 99 !important;
}

.ant-tooltip-content {
  top: 10px !important;
}

.ant-tooltip-arrow {
  top: 10px !important;
  width: 20px;
}

.ant-tooltip-inner {
  padding: 12px 10px !important;
}

.rank-no {
  background: linear-gradient(180deg, #646464 0%, #181818 100%);
  padding: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #ffffff3d;
}

.rank-no.big {
  width: 56px;
  height: 56px;
  font-size: 32px;
}

.ant-dropdown-menu-title-content {
  display: block !important;
}
