.rank-table-container {
  th {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(153, 153, 153, 0.04) 100%
    );
  }

  .th-text {
    padding: 16px 20px;
    text-align: left;
  }

  td {
    text-align: left;
    background: #ffffff11;
  }

  tbody {
    display: block;
    overflow-y: auto;
    max-height: 420px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Optional: Helps columns maintain consistent width */
  }

  .full-page thead tr,
  .full-page tbody tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .full-page thead th,
  .full-page tbody td {
    width: 100%;
  }
}

.rank-system-table {
  th,
  td {
    border: 1px solid #ffffff0f;
  }

  tr {
    th,
    td {
      padding-left: 24px;
    }
  }
}
