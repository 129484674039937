.other-cryptos-modal,
.buy-token-modal,
.referral-modal,
.change-network-modal {
  /* .ant-modal {
    height: 700px;
  } */

  .ant-modal-mask {
    background: #2c2c2ce5;
  }

  .ant-modal-content {
    padding: 24px 0 36px 0;
    border-radius: 24px;
    background: black !important;
    /* height: 700px; */
  }

  .ant-modal-header {
    background: black;
    margin-bottom: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #ffffff1a;
  }

  .ant-modal-body {
    padding: 24px 24px 0 24px;
  }

  .ant-modal-title {
    font-size: 18px;
    color: white;
    font-weight: 500;
    text-align: center;
    line-height: 100%;
  }

  .ant-modal-close {
    top: 16px;
    inset-inline-end: 16px;
  }

  .ant-modal-close-x {
    font-size: 20px;
  }

  .ant-modal-close-icon {
    color: #ffffff;
  }

  .ant-modal-close-icon:hover {
    color: #ffffffcc;
  }

  .ant-select-selection-placeholder {
    font-size: 16px;
    color: #ffffff99;
  }
}

.other-cryptos-modal {
  .ant-modal-content {
    height: 599px;
  }
}

.ant-modal-footer {
  margin-top: 0 !important;
}

.change-network-modal {
  .ant-modal-content {
    padding: 24px 0 46px 0 !important;
  }
}
