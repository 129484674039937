.notification-dropdown {
  top: 88px !important;
  right: 20px !important;
  width: 505px;
  .ant-dropdown-menu {
    min-height: 208px;
    max-height: 710px;
    overflow-y: scroll;
    padding: 0 0 16px 0;
    background-color: #000000; /* Change to your desired color */
    color: #ffffff;
    border: 2px solid #ffffff1a;
    border-radius: 24px;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
    .notification-hr {
      width: 100%;
      border-color: #ffffff1a;
      border-width: 1px;
    }
  }

  .noti-content-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #ffffff80;
    /* margin-bottom: 8px; */
  }
}

.select-chain,
.select-token {
  height: auto !important;
  color: white;
  background: #000000 !important;
  padding: 0 !important;
  border-radius: 12px !important;

  .ant-select-selector {
    border-radius: 12px !important;
    height: 54px !important;
    background: #ffffff1a !important;
    border: 1px solid #ffffff1a !important;
  }

  .ant-select-selector:hover {
    background: #ffffff4d !important;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background: #ffffff1a !important;
  }

  .rc-virtual-list {
    /* max-height: none !important; */
    border-radius: 12px;
    border: 1px solid #ffffff1a;
  }

  /* Optional: control the initial state if needed */
  .ant-select-arrow {
    color: white;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    top: 26px;
  }
}

/* Rotate arrow up when the dropdown is open */
.ant-select-open .ant-select-arrow {
  transform: rotate(180deg) !important;
  transition: transform 0.3s ease-in-out !important;
}

.select-chain {
  width: 163px;
}

.select-token {
  width: 100%;
  /* height: 200px !important; */
}
